import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/food-stamps-application-Texas.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const FSAT = () => {
  
  useEffect(() => {
    const gtmId = "GTM-TRWQ825J"; // Replace with your GTM ID
    if (!gtmId) {
      console.error("GTM ID is missing.");
      return;
    }

    // Inject GTM script dynamically
    const script = document.createElement("script");
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script);

    // Inject noscript fallback
    const noScript = document.createElement("noscript");
    noScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" 
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noScript);

    // Cleanup script and noscript on component unmount
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noScript);
    };
  }, []);

  
    
  return (
    <>
    <Helmet>
        <title>How to Apply for Food Stamps in Texas | SBA Tax Consultants</title>
        <meta name="description" content="Need help with the Texas food stamps application? Learn the requirements, steps, and how SBA Tax Consultants can assist in making the process simple and quick" />
        <meta name="keywords" content="Texas SNAP Application, Apply for Food Stamps in Texas, Texas Food Stamp Program, Food Assistance Texas, Texas EBT Application" />
        <meta name="robots" content="index, follow" />
        <meta name="YahooSeeker" content="Index,Follow" />
        <meta name="Googlebot" content="Index,Follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="classification" content="How to Apply for Food Stamps in Texas | SBA Tax Consultants" />
        <meta name="allow-search" content="Yes" />
        <meta name="distribution" content="global" />
        <meta name="Content-Language" content="EN" />
        <meta name="geo.region" content="US-TX" />
        <meta name="geo.placename" content="Dallas" />
        <meta name="zipcode" content="75247" />  
        <meta name="expires" content="never" />
        <meta name="language" content="English" />
        <meta name="author" content="SBA Tax Consultants" />
        <meta name="rating" content="General" />
        <meta name="audience" content="All" />
        <meta name="publisher" content="SBA Tax Consultants" />
        <meta name="city" content="Dallas" />
        <meta name="State" content="Texas" />
        <meta name="twitter:creator" content="@sbataxconsult"/>
        <meta name="twitter:site" content="@sbataxconsult"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="How to Apply for Food Stamps in Texas | SBA Tax Consultants" />
        <meta name="twitter:image" content="https://tinyurl.com/bdc9bupb"  />
        <meta name="https://sbataxconsultants.com/food-stamps-application-texas" content="Texas SNAP Application, Apply for Food Stamps in Texas" />
        <link href="https://sbataxconsultants.com/opensearch.xml" rel="search" title=" How to Apply for Food Stamps in Texas | SBA Tax Consultants."type="application/opensearchdescription+xml" />
        <link rel="canonical" href="https://sbataxconsultants.com/food-stamps-application-texas" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="How to Apply for Food Stamps in Texas | SBA Tax Consultants" />
        <meta property="og:description" content="Need help with the Texas food stamps application? Learn the requirements, steps, and how SBA Tax Consultants can assist in making the process simple and quick" />
        <meta property="og:url" content="https://sbataxconsultants.com/food-stamps-application-texas" />
        <meta property="og:site_name" content="How to Apply for Food Stamps in Texas | SBA Tax Consultants"/>
        <meta property="og:url" content="https://sbataxconsultants.com/money-services-business-license-usa" />
        <meta property="og:site_name" content="MSB License USA - SBA Tax Consultants | Compliance & Registration"/>
        <meta property="og:image" content="https://tinyurl.com/bdc9bupb" />
        <meta property="og:image:secure_url " content="https://tinyurl.com/bdc9bupb" />
        <meta property="og:image:width" content="1080"/>
        <meta property="og:image:height" content="1080"/>
        <meta property="og:image:type" content="image/jpg" />
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Food Stamps Application Texas</strong></h1>
                <p className='text-white py-3 ppp'>"Apply for Food Stamps in Texas with ease. SBA Tax Consultants provide expert assistance with your Texas SNAP application to ensure you get the support you need."</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Texas SNAP Application Made Easy with <span className='primary-text'>SBA Tax Consultants</span></b></h2>
            <p>Navigating the Texas SNAP Application process can be simple with the proper support. At SBA Tax Consultants, we specialize in food assistance in Texas, helping you easily apply for food stamps in Texas. Our team ensures you understand every step of the Texas Food Stamp Program, including the Texas EBT application. With our expertise in food assistance in Texas, we streamline the process, making it smooth and stress-free so you can access the benefits you deserve.</p>
            
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default FSAT;