import React, { useState, useEffect } from 'react';
import Inc_Sticky_Form from '../Inc_Sticky_Form';
import topImage from '../img/insuranceCon/common-banner.jpg'
import {Helmet} from "react-helmet";
export default function Insurance() {

  useEffect(() => {
    const gtmId = "GTM-TRWQ825J"; // Replace with your GTM ID
    if (!gtmId) {
      console.error("GTM ID is missing.");
      return;
    }

    // Inject GTM script dynamically
    const script = document.createElement("script");
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script);

    // Inject noscript fallback
    const noScript = document.createElement("noscript");
    noScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" 
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noScript);

    // Cleanup script and noscript on component unmount
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noScript);
    };
  }, []);
    const [styles, setStyles] = useState({
        position: 'static',
        top: '0px',
        width: '100%',
        visibility: 'visible'
      });
    
      useEffect(() => {
        const handleScroll = () => {
          const mq = window.matchMedia("(min-width: 768px)");
    
          if (mq.matches) {
            const sTop = window.scrollY;
    
            if (sTop > 400 && sTop <= 1450) {
              setStyles({
                position: 'fixed',
                top: '150px',
                width: '30%',
                right:'3%',
                visibility: 'visible'            
              });
            }
    
            if (sTop >1450) {
              setStyles({
                position: 'static',
                top: '150px',
                width: '23%',
                visibility: 'hidden'
              });
            }
    
            if (sTop < 350) {
              setStyles({
                position: 'static',
                top: '0px',
                width: '100%',
                visibility: 'visible'
              });
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  return (
    <>
    <Helmet>
        <title>General Liability Insurance in USA | Property tax and insurance</title>
        <meta name="description" content="Get comprehensive insurance solutions for your business with SBA Tax Consultants. From liability to commercial property insurance." />
        <meta name="keywords" content="insurance for businesses, liability insurance for businesses, insuring a small business, commercial property insurance, business insurance texas, Insurance Tax Services, Tax Preparer Insurance." />
        <meta name="robots" content="index, follow" />
        <meta name="YahooSeeker" content="Index,Follow" />
        <meta name="Googlebot" content="Index,Follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="classification" content="General Liability Insurance in USA | Property tax and insurance" />
        <meta name="allow-search" content="Yes" />
        <meta name="distribution" content="global" />
        <meta name="Content-Language" content="EN" />
        <meta name="geo.region" content="US-TX" />
        <meta name="geo.placename" content="Dallas" />
        <meta name="zipcode" content="75247" /> 
        <meta name="expires" content="never" />
        <meta name="language" content="English" />
        <meta name="author" content="SBA Tax Consultants" />
        <meta name="rating" content="General" />
        <meta name="audience" content="All" />
        <meta name="publisher" content="SBA Tax Consultants" />
        <meta name="city" content="Dallas" />
        <meta name="State" content="Texas" />
        <meta name="twitter:creator" content="@sbataxconsult"/>
        <meta name="twitter:site" content="@sbataxconsult"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="General Liability Insurance in USA | Property tax and insurance" />
        <meta name="twitter:image" content="https://sbataxconsultants.com/static/media/common-banner.17d09c5e21157f7e8e8f.jpg"  />
        <meta name="https://sbataxconsultants.com/insurance" content="insurance for businesses" />
        <link href="https://sbataxconsultants.com/opensearch.xml" rel="search" title="Liability Insurance for Businesses | Property Insurance" typ="application/opensearchdescription+xml" />
        <link rel="canonical" href="https://sbataxconsultants.com/insurance" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="General Liability Insurance in USA | Property tax and insurance" />
        <meta property="og:description" content="Get comprehensive insurance solutions for your business with SBA Tax Consultants. From liability to commercial property insurance." />
        <meta property="og:url" content="https://sbataxconsultants.com/insurance" />
        <meta property="og:site_name" content="General Liability Insurance in USA | Property tax and insurance"/>
        <meta property="og:image" content="https://sbataxconsultants.com/static/media/common-banner.17d09c5e21157f7e8e8f.jpg"  />
        <meta property="og:image:secure_url " content="https://sbataxconsultants.com/static/media/common-banner.17d09c5e21157f7e8e8f.jpg" />
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="548" />
        <meta property="og:image:type" content="image/jpg" />
    </Helmet>
    <section>
          <img src={topImage} className='img-fluid w-100 bannerImg' alt='img' />
          {/* <div className="container">            
              <div className='bannerText'>
                  <h1>Insurance </h1>
                  <p className=''>Certified Public Accountants (CPAs) may not directly sell insurance, but they can offer guidance on various types of insurance that businesses or individuals might consider. </p>
              </div>            
          </div> */}
          
    </section>
    
    <section>
      <div className="container py-5">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='h2 primary-text'>Insurance Tax Services</h1>
            
                <h6 className=''>We don't directly sell insurance, but we can offer guidance on various types of insurance that businesses or individuals might consider. </h6>
               
                <h5 className='py-2 primary-text'>Here are some common types of insurance and their benefits:</h5>              
                
            
                <div className="border-bottom">
                    <h5 className='primary-text'>General Liability Insurance:</h5>   
                    <p>Protects businesses from claims of bodily injury, property damage, or personal injury. It covers legal costs and damages awarded in lawsuits against the business.</p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Professional Liability Insurance (Errors and Omissions):</h5>   
                    <p>Provides coverage against claims of professional negligence or mistakes that result in financial loss to clients. It's crucial for professions like lawyers, accountants, consultants, and other service-based businesses.</p>
                </div>
        
                <div className="border-bottom">
                    <h5 className='primary-text'> Property Insurance:</h5>   
                    <p>Protects physical assets (buildings, equipment, inventory) against damage or loss due to fire, theft, vandalism, or natural disasters. </p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Workers' Compensation Insurance: </h5>   
                    <p>Required by law in most states for businesses with employees. It covers medical expenses and lost wages for employees injured on the job and shields businesses from lawsuits related to workplace injuries. </p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Business Interruption Insurance: </h5>   
                    <p>Covers lost income and expenses during periods when the business can't operate due to a covered event (like fire or natural disaster). It helps businesses recover from financial losses during downtime. </p>
                </div>
        
                <div className="border-bottom">
                    <h5 className='primary-text'>Health Insurance: </h5>   
                    <p>Provides coverage for medical expenses and can be offered as part of employee benefits. CPAs might advise on structuring health insurance plans for businesses to attract and retain talent. </p>
                </div>
            
                <div className="border-bottom">
                    <h5 className='primary-text'>Life Insurance and Disability Insurance: </h5>   
                    <p>Provides financial protection for individuals and their families in case of death or disability. It ensures financial stability by replacing lost income or covering expenses. </p>
                </div>
        
                <div className="">
                    <h6 className='primary-text'>The availability and specifics of insurance coverage can differ based on states in the USA due to varying regulations and local insurance market conditions. States may have different requirements, limits, and regulations regarding certain types of insurance. For example:</h6>                
                </div>
        
                <div className="border-bottom">
                    
                    <p><strong>Worker's Compensation:</strong> State laws dictate specific requirements for worker's compensation insurance, including coverage limits and regulations for employers.</p>
                    <p><strong>Health Insurance:</strong> Some states have additional regulations or requirements for health insurance coverage, including the types of coverage offered and eligibility criteria.</p>
                    <p><strong>Property Insurance:</strong> Certain areas prone to natural disasters (like coastal regions for hurricanes or earthquake-prone regions) might have specific insurance requirements or limitations.</p>
                    
                </div>
        
                <div className="">                
                    <p>CPAs can guide businesses or individuals on the types of insurance suitable for their needs, considering industry-specific risks, legal requirements, and budget constraints. They often work closely with insurance agents or brokers to ensure adequate coverage while optimizing cost-effectiveness for their clients.</p>
                </div>
            </div>
            <div className="col-md-4 fixedElement" style={styles}>
                <Inc_Sticky_Form />
            </div>
        </div>            
      </div>
    </section>
    </>
  )
}
