import React from "react";
import { Link } from "react-router-dom";
import NotFound from './img/not-found.jpg'

const NotFoundPage = () => {
    return (
        <>
        <section>
            <div className="container">
                <div className="row py-5 pb-3 border-bottom justify-content-center align-items-center">
                    <div className="col-md-4">
                        <h1 className="fw-bold">404</h1>
                        <p>Oops! The page you're looking for doesn't exist.</p>
                        
                        <Link className="btn btn-danger my-3" to="/">
                            Go Back Home
                        </Link>
                    </div>
                    <div className="col-md-3">
                        <img src={NotFound} alt="" className="img-fluid" />
                    </div>
                    
                </div>
                
            </div>
        </section>
        </>
        
    );
};



export default NotFoundPage;
