import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/Occupancy-Certificate-USA.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const OCUR = () => {
  

  useEffect(() => {
    const gtmId = "GTM-TRWQ825J"; // Replace with your GTM ID
    if (!gtmId) {
      console.error("GTM ID is missing.");
      return;
    }

    // Inject GTM script dynamically
    const script = document.createElement("script");
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script);

    // Inject noscript fallback
    const noScript = document.createElement("noscript");
    noScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" 
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noScript);

    // Cleanup script and noscript on component unmount
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noScript);
    };
  }, []);
  
    
  return (
    <>
    <Helmet>
        <title>Occupancy Certificate USA: Requirements & Application | SBA Tax</title>
        <meta name="description" content="Get your Occupancy Certificate for new buildings in the USA. Learn requirements, application process and more with SBA Tax Consultants." />
        <meta name="keywords" content="Occupancy Certificate USA Requirements, How to Obtain an Occupancy Certificate USA, Occupancy Certificate Application USA, Occupancy Certificate for New Buildings USA, Occupancy Certificate USA Process" />
        <meta name="robots" content="index, follow" />
        <meta name="YahooSeeker" content="Index,Follow" />
        <meta name="Googlebot" content="Index,Follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="classification" content="Occupancy Certificate USA: Requirements & Application | SBA Tax" />
        <meta name="allow-search" content="Yes" />
        <meta name="distribution" content="global" />
        <meta name="Content-Language" content="EN" />
        <meta name="geo.region" content="US-TX" />
        <meta name="geo.placename" content="Dallas" />
        <meta name="zipcode" content="75247" />  
        <meta name="expires" content="never" />
        <meta name="language" content="English" />
        <meta name="author" content="SBA Tax Consultants" />
        <meta name="rating" content="General" />
        <meta name="audience" content="All" />
        <meta name="publisher" content="SBA Tax Consultants" />
        <meta name="city" content="Dallas" />
        <meta name="State" content="Texas" />
        <meta name="twitter:creator" content="@sbataxconsult"/>
        <meta name="twitter:site" content="@sbataxconsult"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Occupancy Certificate USA: Requirements & Application | SBA Tax" />
        <meta name="twitter:image" content="https://tinyurl.com/4vvxr9hy"  />
        <meta name="https://sbataxconsultants.com/occupancy-certificate-usa-requirements" content="Occupancy Certificate USA Requirements, How to Obtain an Occupancy Certificate USA," />
        <link href="https://sbataxconsultants.com/opensearch.xml" rel="search" title="Occupancy Certificate USA: Requirements & Application | SBA Tax"type="application/opensearchdescription+xml" />
        <link rel="canonical" href="https://sbataxconsultants.com/occupancy-certificate-usa-requirements" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Occupancy Certificate USA: Requirements & Application | SBA Tax" />
        <meta property="og:description" content="Get your Occupancy Certificate for new buildings in the USA. Learn requirements, application process, and more with SBA Tax Consultants." />
        <meta property="og:url" content="https://sbataxconsultants.com/occupancy-certificate-usa-requirements" />
        <meta property="og:site_name" content="Occupancy Certificate USA: Requirements & Application | SBA Tax"/>
        <meta property="og:image" content="https://tinyurl.com/4vvxr9hy" />
        <meta property="og:image:secure_url " content="https://tinyurl.com/4vvxr9hy" />
        <meta property="og:image:width" content="1080"/>
        <meta property="og:image:height" content="1080"/>
        <meta property="og:image:type" content="image/jpg" />

    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Occupancy Certificate USA Requirements</strong></h1>
                <p className='text-white py-3 ppp'>"Need an occupancy certificate in the USA? SBA Tax Consultants can guide you through the requirements and help with the process. Contact us for expert assistance and ensure compliance."</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>  
    

    <section className='whychooseuSS pt-5'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Streamline Your Occupancy Certificate USA Requirements with <span className='primary-text'>SBA Tax Consultants</span></b></h2>
            <p>Navigating Occupancy Certificate USA Requirements can be complex, but SBA Tax Consultants simplify the process. Discover how to obtain an occupancy certificate in the USA with our expert guidance. Whether you need an Occupancy Certificate for New Buildings USA or assistance with the Occupancy Certificate Application USA, our team is here to make the process smooth and efficient. Trust us to handle all the details and ensure compliance so you can focus on what matters most.</p>
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default OCUR;