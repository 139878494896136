import React from 'react'

import icon1 from '../img/icons/office-building.png'
import icon2 from '../img/icons/expertise.png'
import icon3 from '../img/icons/rate.png'
import { useEffect } from 'react'

export default function BelowSliderSec() {

  useEffect(() => {
    const gtmId = "GTM-TRWQ825J"; // Replace with your GTM ID
    if (!gtmId) {
      console.error("GTM ID is missing.");
      return;
    }

    // Inject GTM script dynamically
    const script = document.createElement("script");
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script);

    // Inject noscript fallback
    const noScript = document.createElement("noscript");
    noScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" 
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noScript);

    // Cleanup script and noscript on component unmount
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noScript);
    };
  }, []);


  return (
    <section className='py-5 xx abouttt'>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h3 className='fw-bold'><strong><img src={icon1} alt="" className="img-fluid iconn" /> Head Office USA</strong></h3>
            <p>Our head office works 24/7 for all your tax service needs.</p>
          </div>
          <div className="col-md-4">
            <h3 className='fw-bold'><strong><img src={icon2} alt="" className="img-fluid iconn" />10+ Years Experience</strong></h3>
            <p>Over 10 years of expertise in tax solutions, We offer expert guidance for all your tax needs.</p>
          </div>
          <div className="col-md-4">
            <h3 className='fw-bold'><strong><img src={icon3} alt="" className="img-fluid iconn" />Client Satisfaction</strong></h3>
            <p>We deliver personalized and precise tax solutions, guaranteeing top-notch client satisfaction.</p>
          </div>
        </div>
      </div>
    </section>
  )
}
