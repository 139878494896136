import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/Health-Permit-in-USA.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const HPBU = () => {
  

  useEffect(() => {
    const gtmId = "GTM-TRWQ825J"; // Replace with your GTM ID
    if (!gtmId) {
      console.error("GTM ID is missing.");
      return;
    }

    // Inject GTM script dynamically
    const script = document.createElement("script");
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script);

    // Inject noscript fallback
    const noScript = document.createElement("noscript");
    noScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" 
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noScript);

    // Cleanup script and noscript on component unmount
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noScript);
    };
  }, []);
  
    
  return (
    <>
    <Helmet>
        <title>Health Permit Requirements USA | SBA Tax Consultants</title>
        <meta name="description" content="Get expert help with USA health permit applications and regulations. SBA Tax Consultants streamline your health permit processing." />
        <meta name="keywords" content="Health Permit Requirements USA, USA Health Permit Application, Health Permit for Businesses USA, Health Permit Regulations USA, Health Permit Processing USA." />
        <meta name="robots" content="index, follow" />
        <meta name="YahooSeeker" content="Index,Follow" />
        <meta name="Googlebot" content="Index,Follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="classification" content="Health Permit Requirements USA | SBA Tax Consultants" />
        <meta name="allow-search" content="Yes" />
        <meta name="distribution" content="global" />
        <meta name="Content-Language" content="EN" />
        <meta name="geo.region" content="US-TX" />
        <meta name="geo.placename" content="Dallas" />
        <meta name="zipcode" content="75247" />  
        <meta name="expires" content="never" />
        <meta name="language" content="English" />
        <meta name="author" content="SBA Tax Consultants" />
        <meta name="rating" content="General" />
        <meta name="audience" content="All" />
        <meta name="publisher" content="SBA Tax Consultants" />
        <meta name="city" content="Dallas" />
        <meta name="State" content="Texas" />
        <meta name="twitter:creator" content="@sbataxconsult"/>
        <meta name="twitter:site" content="@sbataxconsult"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Health Permit Requirements USA | SBA Tax Consultants" />
        <meta name="twitter:image" content="https://tinyurl.com/2hwpmmju"  />
        <meta name="https://sbataxconsultants.com/health-permit-for-businesses-usa" content="Health Permit Requirements USA, USA Health Permit Application," />
        <link href="https://sbataxconsultants.com/opensearch.xml" rel="search" title=" Health Permit Requirements USA | SBA Tax Consultants."type="application/opensearchdescription+xml" />
        <link rel="canonical" href="https://sbataxconsultants.com/health-permit-for-businesses-usa" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Health Permit Requirements USA | SBA Tax Consultants" />
        <meta property="og:description" content="Get expert help with USA health permit applications and regulations. SBA Tax Consultants streamline your health permit processing." />
        <meta property="og:url" content="https://sbataxconsultants.com/health-permit-for-businesses-usa" />
        <meta property="og:site_name" content="Health Permit Requirements USA | SBA Tax Consultants"/>
        <meta property="og:site_name" content="MSB License USA - SBA Tax Consultants | Compliance & Registration"/>
        <meta property="og:image" content="https://tinyurl.com/2hwpmmju" />
        <meta property="og:image:secure_url " content="https://tinyurl.com/2hwpmmju" />
        <meta property="og:image:width" content="1080"/>
        <meta property="og:image:height" content="1080"/>
        <meta property="og:image:type" content="image/jpg" />
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Health Permit for Businesses USA</strong></h1>
                <p className='text-white py-3 ppp'>Need a Health Permit for your business in the USA? SBA Tax Consultants offer expert guidance to navigate the permit process smoothly and efficiently. Get started with us today!</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Health Permit Requirements USA with <span className='primary-text'>SBA Tax Consultants</span></b></h2>
            <p>Navigating Health Permit Requirements USA can be challenging, but SBA Tax Consultants make it straightforward. Whether you need help with the USA health permit application or understanding Health Permit Regulations in the USA, our expert team is here to assist. We manage all aspects of health permit processing, ensuring compliance with local and national standards. Trust us to streamline your health permit for businesses in the USA, making the process stress-free and efficient.</p>
            
            
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default HPBU;