import React, { useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/why-chooose-us.jpg'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const TypesOfPermitInUSA = () => {
  
  useEffect(() => {
    const gtmId = "GTM-TRWQ825J"; // Replace with your GTM ID
    if (!gtmId) {
      console.error("GTM ID is missing.");
      return;
    }

    // Inject GTM script dynamically
    const script = document.createElement("script");
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script);

    // Inject noscript fallback
    const noScript = document.createElement("noscript");
    noScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" 
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noScript);

    // Cleanup script and noscript on component unmount
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noScript);
    };
  }, []);

  
    
  return (
    <>
    <Helmet>
        <title>Get Your Types of Permit in USA | SBA Tax Consultants</title>
        <meta name="description" content="Need a building permit in the USA? SBA Tax Consultants make permit applications easy for construction, business,and work permits." />
        <meta name="keywords" content="Building Permit USA, Permit Application USA, Construction Permit USA, Business Permit USA, Work Permit USA" />
        <meta name="robots" content="index, follow" />
        <meta name="YahooSeeker" content="Index,Follow" />
        <meta name="Googlebot" content="Index,Follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="classification" content="Get Your Types of Permit in USA | SBA Tax Consultants" />
        <meta name="allow-search" content="Yes" />
        <meta name="distribution" content="global" />
        <meta name="Content-Language" content="EN" />
        <meta name="geo.region" content="US-TX" />
        <meta name="geo.placename" content="Dallas" />
        <meta name="zipcode" content="75247" />  
        <meta name="expires" content="never" />
        <meta name="language" content="English" />
        <meta name="author" content="SBA Tax Consultants" />
        <meta name="rating" content="General" />
        <meta name="audience" content="All" />
        <meta name="publisher" content="SBA Tax Consultants" />
        <meta name="city" content="Dallas" />
        <meta name="State" content="Texas" />
        <meta name="twitter:creator" content="@sbataxconsult"/>
        <meta name="twitter:site" content="@sbataxconsult"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Get Your Types of Permit in USA | SBA Tax Consultants" />
        <meta name="twitter:image" content="https://tinyurl.com/yc8z4hp5"  />
        <meta name="https://sbataxconsultants.com/types-of-permit-in-usa" content="Building Permit USA, Permit Application USA," />
        <link href="https://sbataxconsultants.com/opensearch.xml" rel="search" title="Get Your Types of Permit in USA | SBA Tax Consultants."
        type="application/opensearchdescription+xml"/>
        <link rel="canonical" href="https://sbataxconsultants.com/types-of-permit-in-usa" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Get Your Types of Permit in USA | SBA Tax Consultants" />
        <meta property="og:description" content="Need a building permit in the USA? SBA Tax Consultants make permit applications easy for construction, business,and work permits." />
        <meta property="og:url" content="https://sbataxconsultants.com/types-of-permit-in-usa" />
        <meta property="og:site_name" content="Get Your Types of Permit in USA | SBA Tax Consultants"/>
        <meta property="og:image" content="https://tinyurl.com/yc8z4hp5"  />
        <meta property="og:image:secure_url " content="https://tinyurl.com/yc8z4hp5" />
        <meta property="og:image:width" content="1080"/>
        <meta property="og:image:height" content="1080"/>
        <meta property="og:image:type" content="image/jpg" />
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Expert Permit Services in the USA</strong></h1>
                <p className='text-white py-3 ppp'>Get hassle-free permit approvals with our expert services. We handle all permit types across the USA, ensuring quick and compliant processing. Trust our expertise for smooth and efficient results.</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Streamlined Permit Services with <span className='primary-text'> SBA Tax Consultants</span></b></h2>
            <p>At SBA Tax Consultants, we simplify your permit needs with expert assistance, specializing in Building Permit USA and Work Permit USA. Our team also handles Permit Applications USA, Construction Permits USA, and Business Permits USA. We ensure that all your permits are processed with utmost efficiency, relieving you of any unnecessary delays and allowing you to focus on what matters most.</p>
            <p>Trust us for professional support and hassle-free permit solutions.</p>
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default TypesOfPermitInUSA;