import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/weight-measurement-certificate-USA.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const WMC = () => {
  

  useEffect(() => {
    const gtmId = "GTM-TRWQ825J"; // Replace with your GTM ID
    if (!gtmId) {
      console.error("GTM ID is missing.");
      return;
    }

    // Inject GTM script dynamically
    const script = document.createElement("script");
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script);

    // Inject noscript fallback
    const noScript = document.createElement("noscript");
    noScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" 
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noScript);

    // Cleanup script and noscript on component unmount
    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noScript);
    };
  }, []);
  
    
  return (
    <>
    <Helmet>
        <title>Certified Weighing & Measurement Services | SBA Tax Consultants</title>
        <meta name="description" content="Get expert weight certification and measurement compliance in the USA with SBA Tax Consultants. Trusted US weight & measurement services." />
        <meta name="keywords" content="Weight Certification USA, Measurement Certificate USA, US Weight and Measurement Services, Certified Weighing and Measurement USA, Weight Measurement Compliance USA" />
        <meta name="robots" content="index, follow" />
        <meta name="YahooSeeker" content="Index,Follow" />
        <meta name="Googlebot" content="Index,Follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="classification" content="Certified Weighing & Measurement Services | SBA Tax Consultants" />
        <meta name="allow-search" content="Yes" />
        <meta name="distribution" content="global" />
        <meta name="Content-Language" content="EN" />
        <meta name="geo.region" content="US-TX" />
        <meta name="geo.placename" content="Dallas" />
        <meta name="zipcode" content="75247" />  
        <meta name="expires" content="never" />
        <meta name="language" content="English" />
        <meta name="author" content="SBA Tax Consultants" />
        <meta name="rating" content="General" />
        <meta name="audience" content="All" />
        <meta name="publisher" content="SBA Tax Consultants" />
        <meta name="city" content="Dallas" />
        <meta name="State" content="Texas" />
        <meta name="twitter:creator" content="@sbataxconsult"/>
        <meta name="twitter:site" content="@sbataxconsult"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Certified Weighing & Measurement Services | SBA Tax Consultants" />
        <meta name="twitter:image" content="https://tinyurl.com/3j7adkmx"  />
        <meta name="https://sbataxconsultants.com/weight-and-measurement-certificate-usa" content="Weight Certification USA, Measurement Certificate USA" />
        <link href="https://sbataxconsultants.com/opensearch.xml" rel="search" title="Certified Weighing & Measurement Services | SBA Tax Consultants"type="application/opensearchdescription+xml" />
        <link rel="canonical" href="https://sbataxconsultants.com/weight-and-measurement-certificate-usa" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Certified Weighing & Measurement Services | SBA Tax Consultants" />
        <meta property="og:description" content="Get expert weight certification and measurement compliance in the USA with SBA Tax Consultants. Trusted US weight & measurement services." />
        <meta property="og:url" content="https://sbataxconsultants.com/weight-and-measurement-certificate-usa" />
        <meta property="og:site_name" content="Certified Weighing & Measurement Services | SBA Tax Consultants"/>
        <meta property="og:image" content="https://tinyurl.com/3j7adkmx" />
        <meta property="og:image:secure_url " content="https://tinyurl.com/3j7adkmx" />
        <meta property="og:image:width" content="1080"/>
        <meta property="og:image:height" content="1080"/>
        <meta property="og:image:type" content="image/jpg" />

    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Weight & Measurement Certificate USA</strong></h1>
                <p className='text-white py-3 ppp'>Looking for a Weight & Measurement certificate in the USA? SBA Tax Consultants offer expert assistance with certification requirements and processing. Contact us for reliable, efficient service.</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Certified Weighing and Measurement Services in the USA</b></h2>
            <p>At SBA Tax Consultants, we make meeting your business's needs easy. At SBA Tax Consultants, we deliver exceptional US Weight and Measurement Services. Our expert team ensures your business meets all Weight Measurement Compliance USA standards. We offer reliable solutions tailored to your needs, from providing accurate Weight Certification USA to issuing a Measurement Certificate USA. Trust us for professional, certified weighing and measurement services that ensure compliance and precision.</p>
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default WMC;